import { useHistory } from "react-router-dom";
import { Avatar, Badge, Grid, IconButton } from "@mui/material";
import { BellIcon, MenuIcon } from "@assets";
import { useDispatch } from "react-redux";

import { ellipsisText, linkImage } from "@utils";
import { StyledAppTopBar } from ".";
import { AppIconButton, AppModal } from "..";
import { ModalMenu } from "@components";
import logo from "@assets/Icons/logo.svg";
import { appRouteEnums } from "@enums";
import { resetMenu, selectMenu, useAppSelector, setMenu, selectAuth, selectNoti } from "@redux";
import { GREETING_HELLO, NUMBER_TEXT_USERNAME } from "@configs";
import { StyledStickyHeader } from "src/Components/styles";
// import { HiOutlineClipboardList } from "react-icons/hi";

type IProps = {
  screen?: string;
};

export const AppTopBar = (props: IProps) => {
  const { screen } = props;
  // redux state
  const menu = useAppSelector(selectMenu);
  const auth = useAppSelector(selectAuth);
  const noti = useAppSelector(selectNoti);
  const { userInfo } = auth;
  const { show } = menu;
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  // event close modal
  const handleCloseModal = () => {
    dispatch(resetMenu());
  };
  // event open modal
  const handleOpenModal = () => {
    dispatch(setMenu());
  };
  // get width of screen
  return (
    <StyledStickyHeader>
    <StyledAppTopBar screen={screen}>
      <div className="topbar">
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={1} md={1} container justifyContent="space-between">
            <span className="topbar__logo" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
              <img src={logo} alt="logo" />
            </span>
            <p className="toHome">ホーム画面へ</p>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <div className="topbar__content">
              <div className="topbar__left">
                <img className="topbar__left-smallLogo" src={logo} alt="logo" onClick={() => history.push(appRouteEnums.PATH_HOME)} />
              </div>
              <div className="topbar__right">
                <div className="topbar__userInfo" onClick={() => history.push(appRouteEnums.PATH_PROFILE)}>
                  <Avatar src={linkImage(userInfo?.avatar?.path)} />
                  <div className="topbar__userInfo-text">
                    <span className="subText" onClick={() => history.push(appRouteEnums.PATH_PROFILE)}>
                      {GREETING_HELLO}
                    </span>
                    <span className="mainText">{ellipsisText(`${userInfo?.last_name}${userInfo?.first_name}`, NUMBER_TEXT_USERNAME)}</span>
                  </div>
                </div>
                {screen !== "home" && (
                  <div className="topbar__right-btnMenu">
                    <p className="menuText">メニュー</p>
                    <AppIconButton onClick={handleOpenModal} icon={<MenuIcon />} />
                  </div>
                )}

                <div className="topbar__right-btnBell">
                  <IconButton onClick={() => history.push(appRouteEnums.PATH_NOTIFICATION)} size="large">
                    <Badge
                      badgeContent={noti.numberNoti}
                      color="warning"
                      overlap="circular"
                      style={{
                        transform: "translate(30px, -20px)",
                        fontSize: "1.4rem",
                      }}
                    ></Badge>
                    <BellIcon />
                  </IconButton>
                  {/* </Tooltip> */}
                </div>
                {/* <div className="topbar__right-btnBell">
                                    <IconButton
                                        onClick={() =>
                                            history.push(appRouteEnums.PATH_LIST_DOCUMENT)
                                        }
                                        size="large"
                                    >
                                        <Badge
                                            badgeContent={noti.numberNoti}
                                            color="warning"
                                            overlap="circular"
                                            style={{
                                                transform: "translate(30px, -20px)",
                                                fontSize: "1.4rem",
                                            }}
                                        ></Badge>
                                        <HiOutlineClipboardList size={24} />
                                    </IconButton>
                                </div> */}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <AppModal open={show} handleClose={handleCloseModal}>
        <ModalMenu />
      </AppModal>
    </StyledAppTopBar>
    </StyledStickyHeader>
  );
};
