import { flatArrayToString } from "@utils";
import axiosClient from "./axiosClient";

export const weeklyScheduleApi = {
    // getListWorksInConstruction: (params: any, arrayGroupId: any, sort_value: any) => {
    //     let url = `api/get-weekly-schedule-by-month?year=${params.year}&month=${params.month}&limit=${params.limit}&page=${params.page}&sort_value=${sort_value}`;
    //     if (arrayGroupId?.length) {
    //         url += flatArrayToString("groupId", arrayGroupId);
    //     }
    //     return axiosClient.get(url);
    // },
    getListWorksInConstruction: (params: any, arrayGroupId: any, sort_value: any, user_id: any) => {
        let url = `api/get-weekly-schedule-by-month?year=${params.year || ""}&month=${params.month || ""}&limit=${params.limit || ""}&page=${params.page || ""}`;
        if (sort_value) {
            url += `&sort_value=${sort_value}`;
        }
        if (user_id) {
            url += `&user_id=${user_id}`;
        }
        if (arrayGroupId?.length) {
            url += flatArrayToString("groupId", arrayGroupId);
        }
        return axiosClient.get(url);
    },
    createNoteWeeklySchedule: (body) => {
        const url = "api/create-weekly-schedule";
        return axiosClient.post(url, body);
    },
    updateOrderListName: (params: any) => {
        const url = `api/change-user-position`;
        return axiosClient.post(url, params);
    },
    updateContractorOrder: (params: any) => {
        const url = `api/change-contractor-position`;
        return axiosClient.post(url, params);
    },
};