import React from "react";
import { Grid } from "@mui/material";

import { StyledHomeContent } from "./styles";
import { AppTopBar, AppAlert, MenuComponent, AppModal } from "..";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
import { ListItemsComponent } from ".";
import { homeApi } from "@api";
import { useAppSelector, selectAuth } from "@redux";
import { logItemType } from "src/types";

export const HomeContentComponent = () => {
    const [showModal, setShowModal] = React.useState(false);
    const [logItems, setLogItems] = React.useState<logItemType[]>([]);
    const auth = useAppSelector(selectAuth);
    const userToken = auth.tokenInfoAuth;
    const handleCloseModal = () => {
        setShowModal(false);
    };
    // fetch api
    const fetchApiHistoryLogs = async () => {
        try {
            const res = await homeApi.getHistoryLog();
            setLogItems(res.data.data);
        } catch (err) {}
    };
    React.useEffect(() => {
        if (userToken) {
            fetchApiHistoryLogs();
        }
        return () => {
            // Do something cleanup
        };
    }, []);
    return (
        <StyledHomeContent>
            <AppModal open={showModal} handleClose={handleCloseModal}>
                <AppAlert
                    icon={<img src={hot_yellow_frame} alt="icon" />}
                    text="パスワード変更ができました!"
                    handleClose={handleCloseModal}
                />
            </AppModal>
            <div className="home__wrapper">
                <AppTopBar screen="home" />
                <div className="wrapperContent">
                    <div className="home__content">
                        <div className="home__content-menu">
                            <MenuComponent />
                        </div>
                        <div className="home__content-lists">
                            <Grid container spacing={4}>
                                <Grid item xs={3}>
                                    <div className="wrapperText">
                                        <div className="text">
                                            <p>入力履歴</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={9}>
                                    <ListItemsComponent logItems={logItems} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </StyledHomeContent>
    );
};
