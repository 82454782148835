import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

import { appRouteEnums, menuTitleEnums } from "@enums";
import { StyledOverTimeView, StyledWrapCalendarComponent, OverTimeToggleModal } from ".";
import {
    AppTopBar,
    TitleMenuComponent,
    CircularLoader,
    AppIconButton,
    AppCustomeSelect,
    AppDatePicker,
    AppExportCsv,
} from "..";
import { PlusIcon } from "@assets";
import clsx from "clsx";
import { useAppSelector, selectAuth, selectOvertimeLoading, selectOvertimeByMonth, selectEmployeesData, fetchOvertimes } from "@redux";
import { OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { checkExistUserSelected, filterOverTimeTitle, linkImage } from "@utils";
import tick_icon from "@assets/Icons/tick_icon.svg";
import { OverTimeCalendar } from "./OverTimeCalendar";
import styled from "styled-components";
import { useDispatch } from "react-redux";

const defaultGroup = {
    id: 0,
    name: "全て",
};

export const OverTimeView = () => {
    // redux States
    const auth = useAppSelector(selectAuth);
    const id = auth?.userInfo?.id;

    // hooks
    const history = useHistory();
    const dispatch = useDispatch();

    const currentDate = new Date();
    const initialYear = currentDate.getFullYear();
    const initialMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
    const initialCalendarMonth = (`${initialYear}-${initialMonth}`);
    const initialCsvStartDate = new Date(Date.UTC(initialYear, currentDate.getMonth(), 1));
    const initialCsvEndDate = new Date(Date.UTC(initialYear, currentDate.getMonth() + 1, 0));

    // component states
    // const [radioValue, setRadioValue] = React.useState<any>("0");
    const [inputValue, setInputValue] = React.useState<any>("");
    // const [listOT, setListDate] = React.useState<Array<any>>();
    const [users, setUsers] = React.useState<any>([]);
    const [selectedGroup, setSelectedGroup] = React.useState<any>([]);
    // const [optionGroups, setOptionGroup] = React.useState<any>([]);
    // const [optionReasonOT, setOptionReasonOT] = React.useState<any>([]);

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [timeSheetDateData, setTimeSheetDateData] = React.useState<any>([]);
    const [overTimeIdData, setOverTimeIdData] = React.useState<any>();
    const [overTimeGroupIdData, setOverTimeGroupIdData] = React.useState<any>();
    // const [timesheetListData, setTimeSheeListData] = React.useState<any>([]);
    const [date, setDate] = React.useState(new Date());
    const [dataListOT, setDataListOT] = React.useState<any>([]);
    const [calendarMonth, setCalendarMonth] = React.useState<string>(initialCalendarMonth);
    const [cvsStartDate, setCvsStartDate] = React.useState<string>(initialCsvStartDate.toISOString().split('T')[0]);
    const [cvsEndDate, setCvsEndDate] = React.useState<string>(initialCsvEndDate.toISOString().split('T')[0]);

    const overtimesLoading = useAppSelector(selectOvertimeLoading);
    const overtimeDataForMonth = useAppSelector(selectOvertimeByMonth(calendarMonth));
    const employeesData = useAppSelector(selectEmployeesData);

    useEffect(() => {
        if (employeesData) {
            setUsers(employeesData);
        }
    }, [employeesData]);

    useEffect(() => {
        if (overtimeDataForMonth) {
            const selectedUserId = parseInt(inputValue?.value) || null;
            const userFilteredOvertimeDataForMonth = filterOvertimesByUser(overtimeDataForMonth, selectedUserId);
            const groupFilteredOvertimeDataForMonth = filterOvertimesByGroup(userFilteredOvertimeDataForMonth, selectedGroup);
            fillOvertimesMultidays(groupFilteredOvertimeDataForMonth);
            setDataListOT(groupFilteredOvertimeDataForMonth);
        } else {
            dispatch(fetchOvertimes({ month: calendarMonth, groups: [...selectedGroup] }));
        }
    }, [calendarMonth, overtimeDataForMonth, inputValue, selectedGroup, dispatch]);


    // events
    const handleOnChange = (value: string) => {
        setInputValue(value);
    };
    // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setRadioValue((event.target as HTMLInputElement).value);
    //     setInputValue("");
    // };
    const handleViewDetail = (values: { otId; userId; status }) => {
        if ((id === values.userId && values.status === OT_STATUS_PENDING) || (id === values.userId && values.status === OT_STATUS_REJECT)) {
            history.push(`${appRouteEnums.PATH_OVERTIME}/edit/${values.otId}`);
        } else {
            history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otId}`);
        }
    };
    const handleCreateNewOT = () => {
        history.push(`${appRouteEnums.PATH_OVERTIME}/new`);
    };
    const handleSelectGroup = (id) => {
        setInputValue("");
        if (id === 0) {
            setSelectedGroup([]);
            return;
        }
        setSelectedGroup(checkExistUserSelected(id, selectedGroup) ? selectedGroup.filter((e) => e !== id) : [...selectedGroup, id]);
    };

    const handleOpenModal = (date: any, id: number) => {
        setOpenModal(true);
        setTimeSheetDateData({
            date: date.startDate[0],
        });
        setOverTimeIdData({
            id,
        });
        // setOverTimeGroupIdData({ groupId });
    };
    const handleOpenModalId = (date: any, id: number, groupId: number) => {
        setOpenModal(true);
        setTimeSheetDateData({
            date: date.startDate,
        });
        setOverTimeIdData({
            id,
        });
        setOverTimeGroupIdData({ groupId });
    };

    const handleCloseToggle = () => {
        setOpenModal(false);
        setTimeSheetDateData([]);
        setOverTimeIdData("");
        setOverTimeGroupIdData("");
    };
    const handleChange = (date: any) => {
        setDate(date);
    };
    const handleCalendarMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedMonth = e.target.value;
        setCalendarMonth(selectedMonth);

        const [year, month] = selectedMonth.split('-').map(Number);

        const firstDay = new Date(Date.UTC(year, month - 1, 1));
        setCvsStartDate(firstDay.toISOString().split('T')[0]);

        const lastDay = new Date(Date.UTC(year, month, 0));
        setCvsEndDate(lastDay.toISOString().split('T')[0]);
    };

    const filterOvertimesByUser = (overtimes, userId) => {
        return (userId
            ? overtimes.map(day => ({ ...day, users: day.users.filter(user => user.id === userId) }))
            : overtimes);
    };

    const filterOvertimesByGroup = (overtimes, selectedGroup) => {
        return overtimes.map((day) => {
            // Filter users for the current day
            const filteredUsers = day.users.filter((user) => {
                // Filter the user's overtimes based on group_id
                const userHasMatchingOvertime = user.overtimes.some((overtime) => {
                    // If no groups are selected, all overtimes are valid. 
                    // Otherwise, only overtimes with a group_id in selectedGroup should be considered.
                    const isGroupMatching = 
                        selectedGroup.length === 0 || selectedGroup.includes(overtime.title);
        
                    return isGroupMatching;
                });
        
                // Include the user if they have at least one matching overtime
                return userHasMatchingOvertime;
            });
        
            // Return a new day object with filtered users. If no users match, this day will have an empty users array.
            return {
                ...day,
                users: filteredUsers,
            };
        });
    };

    function fillOvertimesMultidays(groupFilteredOvertimeDataForMonth) {
        // Helper function to find a day object by date within the groupFilteredOvertimeDataForMonth array
        function findDayByDate(date) {
            return groupFilteredOvertimeDataForMonth.find(day => day.startDate === date);
        }

        // Helper function to check if a user exists in the day's users array
        function findUserInDay(day, userId) {
            return day.users.find(user => user.id === userId);
        }

        // Iterate over each day in the month
        groupFilteredOvertimeDataForMonth.forEach(day => {
            // For each user on this day
            day.users.forEach(user => {
                // For each overtime span of the user
                user.overtimes.forEach(overtime => {
                    const fromDate = new Date(overtime.from);
                    const toDate = new Date(overtime.to);

                    // Iterate through every date from the start (from) to the end (to) of the overtime period
                    for (let date = new Date(fromDate); date <= toDate; date.setDate(date.getDate() + 1)) {
                        // Format the current date to match the "startDate" in the day objects
                        const currentDate = date.toISOString().split('T')[0];

                        // Find the day object for the current date
                        const targetDay = findDayByDate(currentDate);

                        if (targetDay) {
                            // Check if the user already exists in this day
                            let targetUser = findUserInDay(targetDay, user.id);

                            if (!targetUser) {
                                // If user doesn't exist in the day, clone the user and add to that day's users array
                                targetUser = { ...user, overtimes: [overtime] };
                                targetDay.users.push(targetUser);
                            }

                            // Check if the overtime already exists in this user's "overtimes" array
                            const overtimeExists = targetUser.overtimes.some(ot => ot.id === overtime.id);

                            if (!overtimeExists) {
                                // If overtime doesn't exist, add it to this user's "overtimes" array
                                targetUser.overtimes.push(overtime);
                            }
                        }
                    }
                });
            });
        });
    }

    return (
        <StyledOverTimeView>
            <AppTopBar />
            <OverTimeToggleModal openToogle={openModal} anchor="right" handleCloseToggle={handleCloseToggle} timeSheetDateData={timeSheetDateData} id={overTimeIdData} groupId={overTimeGroupIdData} overtimeDataForMonth={overtimeDataForMonth} />
            <div className="overtime">
                <p className="newOT__top-icon" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
                    <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                </p>
                <div className="overtime__title">
                    <TitleMenuComponent text={menuTitleEnums.TITLE_OVERTIME} />
                    <WrapLink onClick={() => history.push(appRouteEnums.PATH_WEEKLY_SCHEDULE)}>週間予定</WrapLink>
                    <div className="overtime__title-btn">
                        <Button style={{ padding: "10px", borderRadius: "5px" }} onClick={() => handleCreateNewOT()} variant="outlined" startIcon={<PlusIcon />}>
                            新規作成
                        </Button>
                    </div>
                </div>
                <div className="overtime__listItem">
                    <div className="overtime__form">
                        <div className="wrapper__filter">
                            <div className="overtime__filter">
                                <div onClick={() => handleSelectGroup(defaultGroup.id)} className={clsx(!selectedGroup.length ? "active" : "", "overtime__filter-item")}>
                                    <span className="title">{defaultGroup.name}</span>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>{!selectedGroup.length && <img src={tick_icon} alt="icon" />}</Box>
                                </div>
                                {filterOverTimeTitle &&
                                    filterOverTimeTitle.map((item) => (
                                        <div key={item.id} onClick={() => handleSelectGroup(item.name)} className={clsx(checkExistUserSelected(item.name, selectedGroup) ? "active" : "", "overtime__filter-item")}>
                                            <span className="title">{item.name}</span>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>{checkExistUserSelected(item.name, selectedGroup) && <img src={tick_icon} alt="icon" />}</Box>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="overtime__search">
                            <div className="wrapperSelect">
                                <AppCustomeSelect
                                    placeholder="人を選択する"
                                    onChange={handleOnChange}
                                    groupUserOptions={
                                        users?.length &&
                                        [...users].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                            return {
                                                label: `${user.last_name}${user.first_name}`,
                                                value: user?.id?.toString(),
                                                avatar: linkImage(user?.avatar?.path),
                                            };
                                        })
                                    }
                                    options={
                                        users?.length &&
                                        [...users].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                            return {
                                                label: `${user.last_name}${user.first_name}`,
                                                value: user?.id?.toString(),
                                                avatar: linkImage(user?.avatar?.path),
                                            };
                                        })
                                    }
                                    value={inputValue}
                                    isShowAvatar
                                />
                            </div>
                        </div>
                    </div>
                    {(overtimesLoading) ? (
                        <div className="wrapperLoading">
                            <CircularLoader loading={overtimesLoading} type="fullContent" />
                        </div>
                    ) : (
                        <StyledWrapCalendarComponent>

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                                <input type="month" className="inputMonth" value={calendarMonth} onChange={handleCalendarMonthChange} />
                            </div>
                            <div className="ot__calendar">
                                <OverTimeCalendar handleOpenModal={handleOpenModal} handleOpenModalId={handleOpenModalId} handleCloseToggle={handleCloseToggle} dataListOT={dataListOT} month={calendarMonth} handleViewDetail={handleViewDetail} />
                            </div>

                            <div className="cvsExportDiv">
                                <span className="csvExportTitle">CSVエクスポート</span>
                                <div className="ot__datePicker">

                                    <div className="dateContainer">

                                    <div className="label">作成日時</div>

                                    <div className="dateSubContainer">
                                      <div className="wrapperDatePicker" style={{display: "none"}}>
                                          <AppDatePicker date={date} handleChange={handleChange} format="yyyy/MM" views={["year", "month"]} />
                                      </div>
                                      <input type="date" className="inputDate" value={cvsStartDate} onChange={(e) => setCvsStartDate(e.target.value)} /><span>から</span>
                                    </div>

                                    <div className="dateSubContainer">
                                      <input type="date" className="inputDate" value={cvsEndDate} onChange={(e) => setCvsEndDate(e.target.value)} /><span>まで</span>
                                    </div>

                                    </div>
                                </div>
                                <div>
                                    <AppExportCsv type={"overtimes"} startDate={cvsStartDate} endDate={cvsEndDate} />
                                </div>
                            </div>
                        </StyledWrapCalendarComponent>
                        // )
                    )}
                </div>
            </div>
        </StyledOverTimeView>
    );
};

const WrapLink = styled.div`
    font-weight: 700;
    margin-top: 27px;

    border: 2px solid orange;
    color: orange;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 8px 10px;
    :hover {
        cursor: pointer;
        color: #d88d02;
        border: 2px solid #d88d02;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        margin-left: 40px;
    }
`;
