import { Grid, Box } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { dailyLogApi, timesheetApi, workScheduleApi } from "@api";
import { MESSAGE_DATA_NOT_FOUND, OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import { appRouteEnums, menuTitleEnums, WorkTypeNameEnums } from "@enums";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { DailyViewTableComponent, StyledDailogView } from ".";
import {
    TitleMenuComponent,
    AppDateRangePicker,
    AppTopBar,
    CircularLoader,
    AppButton,
    AppIconButton,
    AppInput,
    AppCustomeSelect,
} from "..";
import { Formik } from "formik";
import { dailyLogFilterSchema, linkImage, useConstructionSites, } from "@utils";
import { selectAuth, useAppSelector } from "@redux";
import styled from "styled-components";
import useContractors from "src/Utils/Hooks/useContractors";

export const DailylogView = () => {
    // component state
    const auth = useAppSelector(selectAuth);
    const id = auth.userInfo?.id;
    const [dailylogData, setDailyLogData] = React.useState<any>();
    const [loading, setLoading] = React.useState(false);
    const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();
    const [dateRate, setDateRate] = React.useState<any>({
        startDate: new Date(),
        endDate: new Date(),
    });
//    const [minPeople, setMinPeople] = React.useState<number | undefined>(undefined);
//    const [maxPeople, setMaxPeople] = React.useState<number | undefined>(undefined);
    const [listWorkTypes, setListWorkTypes] = React.useState<any>();
    const [optionConstractors, setOptionConstractors] = React.useState<any>();
    const [optionEmployees, setOptionEmployees] = React.useState<any>();

    const initialValuesPackage = {
        siteConstruction: "",
        constructors: "",
        minPeople: "",
        maxPeople: "",
        typeOfWork: "",
        workType1: "",
        workType2: "",
        workType3: "",
        userId: "",
    };
    const existingParametersInStorage = localStorage.getItem('dailySearchParameters');
    if(existingParametersInStorage) {
        const storedParameters = JSON.parse(existingParametersInStorage);
        if(storedParameters) {
//            if(storedParameters.minPeople) {
//                setMinPeople(parseInt(storedParameters.minPeople) || undefined);
//            }
//            if(storedParameters.maxPeople) {
//                setMaxPeople(parseInt(storedParameters.maxPeople) || undefined);
//            }
            Object.keys(initialValuesPackage).forEach(key => {
                if (storedParameters[key] !== undefined) {
                    initialValuesPackage[key] = storedParameters[key];
                }
            });
        }
    }

    // hooks
    const { sortConstructionSites } = useConstructionSites();
    const { updateContractorHistory } = useContractors(); 

    useEffect(() => {
        // Retrieve stored values from local storage when the component mounts
        const existingDatesInStorage = localStorage.getItem('dailySearchDates');
        if(existingDatesInStorage) {
            const storedDates = JSON.parse(existingDatesInStorage);
            if (storedDates) {
                setDateRate(storedDates);
            }
        }
    }, []);

    const history = useHistory();
    React.useEffect(() => {
        fetchListConstructions();
        return () => {
            // clean up
        };
    }, []);
    React.useEffect(() => {
        fetchListEmployee();
        return () => {
            // clean up
        };
    }, []);
    React.useEffect(() => {
        fetchWorkTypeByType();
        return () => {
            // clean up
        };
    }, []);
    React.useEffect(() => {
        fetchListConstractors();
        return () => {
            // clean up
        };
    }, []);
    React.useEffect(() => {
        fetchLisDailyLog();
    }, []);
    // fetch api daily log
    const fetchLisDailyLog = async (values?: any) => {
        setLoading(true);
        setDailyLogData("");
        try {
            const response = await dailyLogApi.filterDailyLog({
                from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                constructionId: values?.siteConstruction?.value,
                contractorId: values?.constructors?.value,
                workType1: values?.workType1?.value,
                workType2: values?.workType2?.value,
                workType3: values?.workType3?.value,
                userId: values?.userId?.value,
                minEmployee: values?.minPeople,
                maxEmployee: values?.maxPeople,
            });
            setLoading(false);
            setDailyLogData(response.data.data);
        } catch (err: any) {
            setLoading(false);
            setDailyLogData("");
        }
    };
    const fetchWorkTypeByType = async () => {
        try {
            const result = await workScheduleApi.getWorkTypeByType();
            setListWorkTypes(result?.data?.data);
            // setOptionConstractors(result.data.data);
        } catch (err) {}
    };
    // fetch api list Work types
    // const fetchListWorkTypesApi = async () => {
    //     try {
    //         const response = await workScheduleApi.getListWorkTypes();
    //         setListWorkTypes(response.data.data);
    //     } catch (err: any) {}
    // };
    // fetch api
    const fetchListEmployee = async () => {
        try {
            const result = await timesheetApi.listEmployees();
            const filteredSortedEmployees = (!result?.data?.data || !Array.isArray(result?.data?.data)) ? [] : result.data.data
                .filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id)))
                .sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0));
            setOptionEmployees(filteredSortedEmployees);
            //setOptionEmployees(result.data.data);
        } catch (err) {}
    };
    const fetchListConstructions = async () => {
        try {
            const result = await timesheetApi.getListConstructions();
            setOptionSiteConstructions(sortConstructionSites(result.data.data.constructions));
        } catch (err) {}
    };
    const fetchListConstractors = async () => {
        try {
            const result = await timesheetApi.getListContractors();
            setOptionConstractors(result.data.data.contractors);
        } catch (err) {}
    };
    // events
    const handleChangeDateRange = (e) => {
        const newDateRange = { startDate: e.startDate, endDate: e.endDate };
        setDateRate(newDateRange);
        localStorage.setItem('dailySearchDates', JSON.stringify(newDateRange));
    };

    const handleSearchParameterChange = (field: string, value, setFieldValue) => {
        setFieldValue(field, value);

        // Retrieve existing parameters from local storage
        const existingParametersInStorage = localStorage.getItem('dailySearchParameters');
        const storedParameters = existingParametersInStorage ? JSON.parse(existingParametersInStorage) : {};

        // Update the specific field with the new value
        storedParameters[field] = value;

        // Store the updated parameters back into local storage
        localStorage.setItem('dailySearchParameters', JSON.stringify(storedParameters));
    };

    const handleViewDetail = (values: { otId; userId; status }) => {
        localStorage.setItem('dailySearchDates', JSON.stringify(dateRate));
        if (
            (id === values.userId && values.status === OT_STATUS_PENDING) ||
            (id === values.userId && values.status === OT_STATUS_REJECT)
        ) {
            history.push(`${appRouteEnums.PATH_OVERTIME}/edit/${values.otId}`);
        } else {
            history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otId}`);
        }
    };
    return (
        <StyledDailogView>
            <AppTopBar />
            <CircularLoader loading={loading} />
            <div className="daily">
                <p
                    className="newOT__top-icon"
                    onClick={() => history.push(appRouteEnums.PATH_HOME)}
                >
                    <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                </p>
                <div className="daily__title">
                    <TitleMenuComponent text={menuTitleEnums.TITLE_DAILY_LOG} />
                </div>
                <Formik
                    initialValues={initialValuesPackage}
                    onSubmit={(values) => {
                        fetchLisDailyLog(values);
                    }}
                    validationSchema={dailyLogFilterSchema}
                >
                    {({ handleSubmit, values, /*handleChange,*/ errors, touched, setFieldValue }) => {
                        return (
                            <div style={{ marginBottom: "20px" }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={3}>
                                        <div className="wrapperDatePicker">
                                            <AppDateRangePicker
                                                value={{
                                                    startDate: dateRate?.startDate,
                                                    endDate: dateRate?.endDate,
                                                }}
                                                onChange={(e) => handleChangeDateRange(e)}
                                                startText="開始"
                                                endText="終了"
                                            />
                                            {/* <AppDatePicker date={date} handleChange={handleChange} /> */}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <label className="label">現場名</label>
                                        <div className="input select">
                                            <AppCustomeSelect
                                                placeholder="現場名"
                                                name="siteConstruction"
                                                value={values.siteConstruction}
                                                groupOptions={null}
                                                options={
                                                    optionSiteConstructions?.length &&
                                                    optionSiteConstructions?.map((item) => {
                                                        return {
                                                            label: item.name,
                                                            value: item.id.toString(),
                                                        };
                                                    })
                                                }
                                                onChange={(value) => {
                                                    handleSearchParameterChange(
                                                        "siteConstruction",
                                                        value,
                                                        setFieldValue
                                                    );
                                                }}
                                                isClearable
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <label className="label">業者</label>
                                        <div className="input select">
                                            <AppCustomeSelect
                                                options={
                                                    optionConstractors?.length &&
                                                    optionConstractors?.map((item) => {
                                                        return {
                                                            label: item.name,
                                                            value: item.id.toString(),
                                                        };
                                                    })
                                                }
                                                name="constructors"
                                                value={values.constructors}
                                                placeholder="請負業者"
                                                groupOptions={null}
                                                onChange={(value) => {
                                                    updateContractorHistory(value);
                                                    handleSearchParameterChange(
                                                        "constructors",
                                                        value,
                                                        setFieldValue
                                                    );
                                                }}
                                                isClearable
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {/* <label className="label">仕事の種類</label>
                                        <AppSelect
                                            options={
                                                listWorkTypes &&
                                                listWorkTypes.map((item) => {
                                                    return {
                                                        label: item.name,
                                                        value: item.id.toString(),
                                                        color: item.color,
                                                    };
                                                })
                                            }
                                            name="typeOfWork"
                                            handleChange={handleChange("typeOfWork")}
                                            value={values.typeOfWork}
                                            placeholder="1つを選択してください"
                                        /> */}
                                        {/* type 1 */}
                                        <label className="label">{WorkTypeNameEnums.TYPE_1}</label>

                                        <AppCustomeSelect
                                            groupOptions={null}
                                            options={
                                                !!listWorkTypes?.type_1?.length &&
                                                listWorkTypes?.type_1?.map((item) => {
                                                    return {
                                                        label: item.name,
                                                        value: item.id.toString(),
                                                        color: item.color,
                                                    };
                                                })
                                            }
                                            name="workType1"
                                            value={values.workType1}
                                            placeholder={WorkTypeNameEnums.TYPE_1}
                                            onChange={(value) => {
                                                handleSearchParameterChange(
                                                    "workType1",
                                                    value,
                                                    setFieldValue
                                                );
                                            }}
                                            isClearable
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {/* type 2 */}
                                        <label className="label">{WorkTypeNameEnums.TYPE_2}</label>
                                        <AppCustomeSelect
                                            name="workType2"
                                            value={values.workType2}
                                            groupOptions={null}
                                            options={
                                                !!listWorkTypes?.type_2?.length &&
                                                listWorkTypes?.type_2?.map((item) => {
                                                    return {
                                                        label: item.name,
                                                        value: item.id.toString(),
                                                        color: item.color,
                                                    };
                                                })
                                            }
                                            onChange={(value) => {
                                                handleSearchParameterChange(
                                                    "workType2",
                                                    value,
                                                    setFieldValue
                                                );
                                            }}
                                            placeholder={WorkTypeNameEnums.TYPE_2}
                                            isClearable
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {/* type 2 */}
                                        <label className="label">{WorkTypeNameEnums.TYPE_3}</label>
                                        {/* <AppSelect
                                            options={
                                                !!listWorkTypes?.type_3?.length &&
                                                listWorkTypes?.type_3?.map((item) => {
                                                    return {
                                                        label: item.name,
                                                        value: item.id.toString(),
                                                        color: item.color,
                                                    };
                                                })
                                            }
                                            name="workType3"
                                            handleChange={handleChange("workType3")}
                                            value={values.workType3}
                                            placeholder={WorkTypeNameEnums.TYPE_3}
                                        />{" "} */}
                                        <AppCustomeSelect
                                            name="workType3"
                                            value={values.workType3}
                                            groupOptions={null}
                                            options={
                                                !!listWorkTypes?.type_3?.length &&
                                                listWorkTypes?.type_3?.map((item) => {
                                                    return {
                                                        label: item.name,
                                                        value: item.id.toString(),
                                                        color: item.color,
                                                    };
                                                })
                                            }
                                            onChange={(value) => {
                                                handleSearchParameterChange(
                                                    "workType3",
                                                    value,
                                                    setFieldValue
                                                );
                                            }}
                                            placeholder={WorkTypeNameEnums.TYPE_3}
                                            isClearable
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {/* type 2 */}
                                        <label className="label">従業員</label>
                                        <AppCustomeSelect
                                            name="userId"
                                            value={values.userId}
                                            groupOptions={null}
                                            options={
                                                !!optionEmployees?.length &&
                                                optionEmployees?.map((item) => {
                                                    return {
                                                        label: `${item.last_name} ${item.first_name}`,
                                                        value: item.id.toString(),
                                                        avatar: linkImage(item?.avatar?.path),
                                                    };
                                                })
                                            }
                                            onChange={(value) => {
                                                handleSearchParameterChange(
                                                    "userId",
                                                    value,
                                                    setFieldValue
                                                );
                                            }}
                                            placeholder={"従業員"}
                                            isClearable
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <div className="wrapperInput">
                                            <div>
                                                <label className="label">最小人数</label>
                                                <AppInput
                                                    name="minPeople"
                                                    handleChange={(inputValue) => {
                                                        handleSearchParameterChange("minPeople", inputValue.toString(), setFieldValue);
                                                    }}
                                                    isNumber
                                                    value={parseFloat(values.minPeople) || undefined}
                                                    error={errors.minPeople}
                                                    touched={touched.minPeople}
                                                />
                                            </div>
                                            <div>
                                                <label className="label">最大人数</label>
                                                <AppInput
                                                    name="maxPeople"
                                                    handleChange={(inputValue) => {
                                                        handleSearchParameterChange("maxPeople", inputValue.toString(), setFieldValue);
                                                    }}
                                                    isNumber
                                                    value={parseFloat(values.maxPeople) || undefined}
                                                    error={errors.maxPeople}
                                                    touched={touched.maxPeople}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Box sx={{ paddingTop: "2rem" }}></Box>
                                <Grid container>
                                    <Grid item xs={12} md={2}>
                                        <AppButton text="検索する" onClick={handleSubmit} />
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    }}
                </Formik>

                {dailylogData?.timesheets?.length ? (
                    <WrapTotal className="total">
                        <div className="total__title">総合計</div>
                        <div className="total__number">
                            {dailylogData?.total_number_of_people
                                ? dailylogData?.total_number_of_people
                                : 0}
                        </div>
                    </WrapTotal>
                ) : (
                    <></>
                )}
                {dailylogData?.timesheets?.length ? (
                    <div className="date">
                        <div className="daily__view">
                            <Grid>
                                <Grid item xs={24} sm={24} md={6} style={{ position: "relative" }}>
                                    <DailyViewTableComponent
                                        data={dailylogData?.timesheets}
                                        handleViewDetail={handleViewDetail}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        {/* {item && (
                                <div className="daily__bottom">
                                    {item?.date && (
                                        <span>{moment(item?.date).format("YYYY-MM-DD")}</span>
                                    )}
                                    {item?.total && <span>総合計: {item.total}</span>}
                                </div>
                            )} */}
                    </div>
                ) : (
                    <div className="errorMessage">
                        <span>{MESSAGE_DATA_NOT_FOUND}</span>
                    </div>
                )}
            </div>
        </StyledDailogView>
    );
};

const WrapTotal = styled.div`
    right: 0;
    position: absolute;
    margin-right: 17%;
    @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        /* margin-right: 25%; */
        display: none;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
        margin-right: 21%;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
        margin-right: 17%;
    }
    .total__title {
        font-weight: bold;
    }
`;
