import styled from "styled-components";

export const StyleTimeSheet = styled.div`
    text-align: left;
    padding-bottom: 4rem;

    .cvsExportDiv {
        display: inline-flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 1rem;
        padding: 2rem;
        gap: 2rem;
        margin-top:1rem;
    }
    .csvExportTitle {
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
        font-size: 1.6rem;
    }
    .wrapperDatePicker {
                width: 18rem;
            }
            .inputDate {
                padding: 1rem;
                border-radius: 1rem;
                border: 1px solid #888;
                font-size: 1.5rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            }
    .tsDatePicker {
      display: flex;
      align-items: center;
      gap: 1rem;
      .label {
          display: flex;
          align-items: center;
      }
    }
    .csvDateContainer {
        @media (min-width: 768px) {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        @media (min-width: 100px) and (max-width: 768px) {
        }
    }

    .timesheet {
        padding: 1rem 1.5rem;
        min-height: 80vh;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        .to-link {
            display: flex;
        }
        &__title {
            padding-bottom: 1rem;
        }
        &__datePicker {
            display: flex;
            .label {
                display: flex;
                align-items: center;
                padding-right: 2rem;
            }
            .wrapperDatePicker {
                width: 18rem;
            }
        }
        &__form {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: flex;
                justify-content: space-between;
            }
        }
        &__filter {
            padding: 2rem 0;
            display: flex;
            overflow-y: auto;
            column-gap: 0.5rem;
            margin-bottom: 2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
                grid-template-columns: auto auto auto auto;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                max-width: 80rem;
                margin-bottom: 0;
            }
            .active {
                border: 1px solid ${(p) => p.theme.colors.secondary} !important;
                .title {
                    color: ${(p) => p.theme.colors.secondary}!important;
                }
            }
            &-item {
                display: flex;
                padding: 0.5rem 1rem;
                border-radius: 1.6rem;
                border: 1px solid ${(p) => p.theme.colors.darkBlack};
                justify-content: center;
                cursor: pointer;
                min-width: 12rem;
                .title {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    padding-right: 1rem;
                    display: block;
                    display: -webkit-box;
                    max-width: 100%;
                    height: 20px;
                    line-height: 1.2;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &__search {
            display: flex;
            align-items: center;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 30rem;
            }
            .wrapperSelect {
                width: 100%;
            }
            z-index: 1000;
        }
        &__calendar {
            padding-top: 2rem;
        }
    }
`;

export const StyledTimeSheetCalendar = styled.div`
    min-height: 80rem;
    .rbc-calendar {
        min-height: 80rem;
    }
    .rbc-event,
    .rbc-day-slot .rbc-background-event {
        background-color: ${(p) => p.theme.colors.white}!important;
    }
    .rbc-month-row {
        overflow: visible !important;
    }
    .rbc-toolbar {
        display: none;
    }
    .rbc-event {
        cursor: pointer;
        position: relative;
        max-height: 180px;
        overflow-y: auto;
        z-index: 10;
    }
    .rbc-event:hover {
        scrollbar-width: 3px;
        overflow-y: auto;
    }
    .rbc-event::-webkit-scrollbar {
        width: 4px;
    }

    .rbc-event::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    .rbc-event::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 4px;
    }

    .rbc-event::-webkit-scrollbar-thumb:hover {
        background-color: #888;
    }
    .rbc-event:focus {
        outline: none;
    }
    .rbc-row-content {
        position: relative;
        user-select: auto;
        /* -webkit-user-select: none; */
        z-index: 10;
    }
    .rbc-selected-cell {
        background: transparent;
    }
    .rbc-today {
        background-color: transparent;
    }
`;
export const StyledEventContent = styled.div`
    .groupUser {
        .mine {
            .content {
                background-color: #b0eefc !important;
                padding: 0.4rem !important ;
            }
            border-radius: 1rem !important;
        }
        .user {
            display: flex;
            flex-direction: column;
            color: ${(p) => p.theme.colors.darkBlack};
            .more {
                &__dot {
                    padding: 0.2rem 0.8rem;
                    text-align: center;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                        display: none;
                    }
                }
            }
            .wrapperAvatar {
                padding: 0.5rem;
                display: flex;
                z-index: 1000;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding: 0.5rem 1.5rem;
                }
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding: 0.5rem;
                    border-radius: 1rem;
                    padding: 0.5rem 0.8rem;
                }
            }
            .content {
                display: flex;
                background-color: #e7e7e7;
                padding: 0;
                border-radius: 1.6rem;
                align-items: center;

                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding: 0.5rem 1rem;
                }
            }
            .name {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                font-size: 1.4rem;
                text-align: center;
                display: none;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    display: block;
                    display: -webkit-box;
                    max-width: 9rem;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-left: 1rem;
                }
            }
            .MuiAvatar-root {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
    .mobile {
        &__dot {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: none;
            }
        }
    }
    .more__text {
        display: none;
        font-size: 1.3rem;
        padding-left: 2rem;
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        color: #656565;
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            display: block;
        }
    }
`;
export const StyledTimeSheetContentModal = styled.div`
    padding: 2.5rem 4rem;

    .toggle {
        &__top {
            &-collapse {
                display: flex;
            }
            .collapse-title {
                font-size: 1.4rem;
            }
            padding-bottom: 2.4rem;
            &-date {
                font-size: 3.6rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            }
            &-icon {
                width: 2.5rem;
                height: 2.5rem;
                cursor: pointer;
            }
        }
        &__user {
            margin-top: 3rem;
            margin-bottom: 3rem;
            cursor: pointer;
            &-item {
                display: flex;
                padding: 0.6rem 1.2rem;
                background-color: #b0eefc;
                border-radius: 1rem;
                margin-bottom: 2rem;
            }
            &-name {
                padding-top: 1rem;
                padding-left: 1rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 1.4rem;
                display: flex;
                align-items: center;
                height: 3.5rem;
                display: -webkit-box;
                max-width: 100%;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .wrapperList {
            max-height: 70vh;
            overflow-y: auto;
        }
        &__groupUser {
            padding-top: 2rem;
            padding-bottom: 2rem;
            &-title {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 1.6rem;
            }
            &-list {
                padding-left: 2rem;

                .item {
                    padding: 1rem 0;
                    display: flex;
                    cursor: pointer;
                    &__title {
                        margin-top: 1rem;
                        padding-left: 1rem;
                        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                        font-size: 1.4rem;
                        display: flex;
                        align-items: center;
                        height: 3.5rem;
                        display: -webkit-box;
                        max-width: 100%;
                        line-height: 1.2;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
`;
export const StyledPopoverList = styled.div`
    display: flex;
    flex-direction: column;
    .wrapItem {
        padding: 2rem;
        cursor: pointer;
        transition: 250ms;
        display: flex;
        justify-content: flex-end;
        &:hover {
            background-color: #43cdec;
        }
    }
    .item {
        font-size: 1.4rem;
        padding: 0 !important;
        &__status {
            padding-left: 1rem;
            &-checked {
                padding: 0.4rem 1rem;
                background-color: #d6fddc;
                display: flex;
                .text {
                    padding-right: 0.5rem;
                    color: #0aba3b;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-unChecked {
                padding: 0.4rem 1rem;
                background-color: #fdd6d6;
                display: flex;
                .text {
                    padding-right: 0.5rem;
                    color: #ba0a0a;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-pending {
                padding: 0.4rem 1rem;
                background-color: #e9e9e9;
            }
        }
    }
`;
